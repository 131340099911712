/* Stylesheets */
import './scss/vendors/bootstrap.scss' // ref: https://getbootstrap.com/docs/5.2/customize/sass/#importing
// import './scss/vendors/swiper.scss' // ref: https://swiperjs.com/get-started#installation
import './scss/style.scss'


/* Scripts */
import $ from 'jquery'
//import { Accordion, Collapse, ScrollSpy, Modal, Tooltip } from 'bootstrap' // ref: https://getbootstrap.com/docs/5.2/getting-started/webpack/#import-bootstrap - Point 3.
//import Swiper, { Navigation, Pagination, EffectFade, Autoplay } from 'swiper' // ref: https://swiperjs.com/get-started#install-from-npm
import 'jquery.idle/vanilla.idle.js';
import { library, dom } from '@fortawesome/fontawesome-svg-core' // ref: https://fontawesome.com/docs/web/setup/packages
import { faLocationDot } from '@fortawesome/pro-solid-svg-icons'
library.add(faLocationDot)
dom.watch()


/* Init Functions on DOMContentLoaded */
const ready = (callback) => {
    if (document.readyState != "loading") callback()
    else document.addEventListener("DOMContentLoaded", callback)
};

const isDebug = process.env.NODE_ENV !== 'production'

/* Variables and functions */
let deviceWidth, deviceHeight;


// initWebpackVersion
const initWebpackVersion = () => {
    if (isDebug)
        console.log(`%c${DESCRIPTION}`, `color: #9ac456; font-size: 18px; font-weight: 700;`, `Version ${VERSION} - Development Environment`)
}

$(window).on('resize', function () {
    deviceWidth = $(window).width();
    deviceHeight = $(window).height();
});


// Energy saver
const initEnergySaver = () => {
    idle({
        onIdle: energySaverShow,
        idle: 600000
    }).start();

    function energySaverShow() {
        $(".energy-saver").on("click", energySaverHide).fadeIn();
    }
    
    function energySaverHide() {
        $(".energy-saver").off("click").fadeOut();
    }
}


/* Ready callback function */
ready(() => {
    deviceWidth = $(window).width();
    deviceHeight = $(window).height();

    initWebpackVersion()
    initEnergySaver()
})